<template style="background-color: white">
  <div id="app-resource-category" class="app-add">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="order" :rules="rules" label-width="100px">
      <el-form-item label="订单类型" prop="type">
        <el-radio-group v-model="order.type" @change="radioTypeChange">
          <el-radio :label="1">课程</el-radio>
          <el-radio :label="4">院校调剂包</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="课程ID" prop="courseId" v-show="showId" >
        <el-input type="number" v-model="order.courseId" placeholder = "请输入课程ID"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="strUsername">
        <el-input type="textarea" :rows="16" v-model="order.strUsername" placeholder = "每行一个手机号"></el-input>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import {UrlEnum,TipEnum} from "../../../public/js/common-vue";
import {getLocalProjectId} from "../../../public/js/common-vue";
import {OrderTypeEnum} from "../../../public/js/common-vue";
import Loading from "@/components/Loading";
export default {
  components: {Loading},
  data() {
    return {
      order:{
        courseId:'',
        strUsername:'',
        usernames:[],
        type:1,
        projectId:getLocalProjectId()
      },
      name: "Add.vue",
      rules:{
        courseId:[{required: true, message:'请输入', trigger:'blur'}],
        strUsername:[{required: true, message:'请输入', trigger:'blur'}],
      },
      showId:true,
      loading: false
    }
  },
  methods: {
    radioTypeChange:function(){
      var type = this.order.type;
      if(type == OrderTypeEnum.UNIVERSITY) {
        this.showId=false;
        this.order.courseId = 0;
      }
      else{
        this.showId=true;
      }
    },
    submit: function (formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.loading = true;
          document.getElementById("layuiadmin-app-form-submit").disabled =  true;

          this.order.strUsername = this.order.strUsername.replaceAll("，","").replaceAll(",,,","").replaceAll(",,","").replaceAll(" ",",");
          this.order.usernames = this.order.strUsername.split("\n");
          console.log(this.order);

          this.$http({
            method: "post",
            url: UrlEnum.ORDER,
            data: this.order,
          })
          .then((res) => {
            this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
            setTimeout(function () {
              window.parent.postMessage({
                msg: '',
                key: 'refresh'
              }, '*')
            }, 800);
          })
          .catch((res) => {
            this.loading = false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
              document.getElementById("layuiadmin-app-form-submit").disabled =  false;
            }
          });
        }
      })
    }
  }
}
</script>

<style scoped>
@import "../../../static/css/add.css";
</style>
